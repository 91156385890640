import moment from "moment";
import { number } from "yup";

interface CLIENT_DETAILS {
  id: any;
  name: string;
  email: string;
  phone: string;
  project_name: string;
  filming_days_num: number;
  list: any;
  new_client: boolean;
}
interface InitialFormProps {
  private_notes: string;
  client_details: CLIENT_DETAILS;
  production_details: any;
  pre_production: any;
  post_production: any;
  talents: any,
  rentals: any;
  custom: any;
  is_quote: boolean | number | null;
  invoice_type:
    | "all"
    | "pre_production"
    | "post_production"
    | "rentals"
    | "custom";
}

interface InitialCrewDataProps {
  size: string;
  location: any;
  arrival_time: any;
  departure_time: any;
  setup_time: any;
  latitude: any;
  longitude: any;
}

interface InitialDayProps {
  filming_date: any;
  crew_package_needed: any;
  crew_package: any;
}

interface InitialTalentPackageProps {
  location: any;
  talent_arrival_time: any;
  talent_departure_time: any;
}

interface InitialTalentDayProps {
  date_of_shoot: any;
  num_of_talents_needed: number;
  coordinator: boolean;
  packages: Array<InitialTalentPackageProps>;
}

interface InitialRentalDayProps {
  date_of_shoot: any;
  location: any;
  rental_start_time: any;
  rental_end_time: any;
}
interface InitialCustomProps {
  item_description: any;
  price: any;
}

export interface InitialApiPaginationResponse {
  count: number;
  next: any;
  previous: any;
  results: any[];
}

export const _initialApiPaginationResponse: InitialApiPaginationResponse = {
  count: 0,
  next: null,
  previous: null,
  results: []
}

export const _CrewData: InitialCrewDataProps = {
  size: "",
  location: "",
  arrival_time: "",
  departure_time: "",
  setup_time: 15,
  latitude: null,
  longitude: null,
};

export const _editData = {
  type: "video",
  title: "",
  description: "",
  item_description: "",
  price: "",
  aspect_ratio: undefined,
  need_animation: false,
  final_run_time_of_intro: 0,
  hours_needed_for_initial_delivery: 0,
  deadline_of_initial_delivery: "",
  no_of_photos_edit: 0,
  is_raw_footage_delivery: false
};

export const _days: InitialDayProps = {
  filming_date: "",
  crew_package_needed: "",
  crew_package: [_CrewData],
};

export const _talent_pacakge_data: InitialTalentPackageProps = {
  location: "",
  talent_arrival_time: "",
  talent_departure_time: "",
}

export const _talent_day: InitialTalentDayProps = {
  date_of_shoot: "",
  num_of_talents_needed: 0,
  coordinator: false,
  packages: [_talent_pacakge_data]
}

export const _rental_day: InitialRentalDayProps = {
  date_of_shoot: "",
  location: "",
  rental_end_time: "",
  rental_start_time: "",
};
export const _items: InitialCustomProps = {
  item_description: "",
  price: "",
};

export const initialInvoiceForm: InitialFormProps = {
  private_notes: "",
  client_details: {
    id: "",
    name: "",
    email: "",
    phone: "",
    project_name: "",
    filming_days_num: 0,
    list: "",
    new_client: false,
  },
  production_details: {
    day: [],
  },
  post_production: {
    no_of_edits: 0,
    edit: [],
  },
  pre_production: {
    make_storyboard: [0, "", 0],
    make_shot_list: [0, "", 1],
    make_schedule: [0, "", 0.5],
    make_script: [0, "", 0],
    handle_renting_the_location: [0, "", 0],
    testimony_questions: [0, "", 0],
    do_location_walkthrough: [0, "", 0],
    do_location_scouting: [0, "", 0],
    need_table_read: [
      0,
      "",
      3,
      "Briefing Crew on the Project Details, Email Communication, etc",
    ],
    need_talent_casting: [0, "", 0],
    need_prop_access: [0, "", 0],
  },
  talents: {
    talent_days_needed: 0,
    days: []
  },
  rentals: {
    rental_days_needed: 0,
    days: [],
  },
  custom: {
    total_items_needed: 0,
    number_of_items: [],
  },
  invoice_type: "all",
  is_quote: null,
};

export const NUMBER_10: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const CREW_SIZE: any[] = [
  { name: "Solo Crew", value: "solo" },
  { name: "Small Crew", value: "small" },
  { name: "Medium Crew", value: "medium" },
  { name: "Large Crew", value: "large" },
  // {name: 'Live Stream', value: 'live_stream'}
];

export interface ClientOptionType {
  id: any;
  name: string;
  email: string;
  phone: any;
  new_client: boolean;
}

export const clientList = [
  { name: "Anup Kumar", email: "anupbhagat23@gmail.com", phone: "+817990" },
  { name: "Sumit Kumar", email: "anupbhagat23@gmail.com", phone: "+817990" },
  { name: "Binit Kumar", email: "anupbhagat23@gmail.com", phone: "+817990" },
  { name: "Rani Kumari", email: "anupbhagat23@gmail.com", phone: "+817990" },
];

export const pre_production_list = [
  {
    key: "Will Voyage Pro  make a shot list?",
    button_val: 1,
    hours_needed: 1,
    summary: "",
    name: "make_shot_list",
    id: "id1",
  },
  {
    key: "Will Voyage Pro  make a schedule?",
    button_val: 1,
    hours_needed: 0.5,
    summary: "",
    name: "make_schedule",
    id: "id2",
  },
  {
    key: "Who Would be creating a script?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "make_script",
    id: "id3",
  },

  {
    key: "Will Voyage Pro  handle renting the location?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "handle_renting_the_location",
    id: "id4",
  },

  {
    key: "Will Voyage Pro  make  testimony questions?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "testimony_questions",
    id: "id5",
    client: true,
  },
  // {
  //   key: "Will Voyage Pro  acquire location permits?",
  //   button_val: false,
  //   hours_needed: 0,
  //   summary: "",
  //   name: "acquire_location_permit",
  //   id: "id6",
  // },

  {
    key: "Will it be the storyboard need to be created?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "make_storyboard",
    id: "id7",
  },
  {
    key: "Will location scouting be needed?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "do_location_scouting",
    id: "id8",
  },
  {
    key: "Will location walkthrough be needed?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "do_location_walkthrough",
    id: "id9",
  },
  // {
  //   key: "Will Voyage Pro  handle purchasing props?",
  //   button_val: false,
  //   hours_needed: 0,
  //   summary: "",
  //   name: "handle_purchasing_prop",
  //   id: "id10",
  // },
  // {
  //     key: 'Will Voyage Pro  handle the labor of getting the props?',
  //     button_val: false,
  //     hours_needed: 0,
  //     summary: '',
  //     name: 'make_storyboard',
  //     id: 'id11'
  // },
  // {
  //   key: "Any need for a rehearsal?",
  //   button_val: false,
  //   hours_needed: 0,
  //   summary: "",
  //   name: "need_rehearsal",
  //   id: "id12",
  // },
  {
    key: "will talent casting be needed?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "need_talent_casting",
    id: "id13",
  },
  {
    key: "Any additional prop Access?",
    button_val: 0,
    hours_needed: 0,
    summary: "",
    name: "need_prop_access",
    id: "id14",
  },
  {
    key: "Any additional project planning hours?",
    button_val: 1,
    hours_needed: 3,
    summary: "",
    name: "need_table_read",
    id: "id15",
  },
];

export const mapCreateClientRequest = (request: any) => {
  return {
    name: request.name,
    category: "video",
    client: request.id,
    filming_days: request.filming_days_num,
  };
};

export const mapInvoiceResponse = (response: any) => {
  const responseData: any = JSON.parse(JSON.stringify(response?.invoice));
  console.log("Invoice is Quote: ", responseData)
  const data: any = {
    project_id: "",
    private_notes: responseData?.private_note,
    client_details: {
      ...response?.client,
      filming_days_num: responseData?.production?.no_of_filming_days,
      project_name: response?.project?.name,
      new_client: Number(responseData?.discount || "0") > 0,
    },
    production_details: {
      day: [],
    },
    post_production: {
      no_of_edits: responseData?.post_production?.no_of_edits || 0,
      edit: [],
    },
    pre_production: responseData?.pre_production,
    talents: responseData.talents ?? { talent_days_needed: 0, days: [] },
    rentals: responseData.rentals ?? { rental_days_needed: 0, days: [] },
    custom: responseData.custom ?? {
      total_items_needed: 0,
      number_of_items: [],
    },
    invoice_type: responseData.invoice_type,
    is_quote: responseData.is_quote
  };
  let idx = 0;
  for (const property in responseData?.production) {
    if (property?.includes("day_")) {
      data.production_details.day[idx] = {
        ...responseData?.production[property],
        crew_package: [],
      };
      const data1: any = data.production_details.day[idx];
      idx = idx + 1;
      let i = 0;
      for (const property1 in responseData?.production[property]) {
        if (
          property1?.includes(`crew_package_`) &&
          property1 !== "crew_package_needed"
        ) {
          data1["crew_package"][i] =
            responseData?.production[property][property1];
          data1["crew_package"][i].departure_time = moment(
            data1["crew_package"][i].departure_time,
            ["HH:mm:ss"]
          ).format("hh:mm A");
          data1["crew_package"][i].arrival_time = moment(
            data1["crew_package"][i].arrival_time,
            ["HH:mm:ss"]
          ).format("hh:mm A");
          i++;
        }
      }
    }
  }

  if (responseData?.rentals?.days.length > 0) {
    responseData.rentals.days = responseData?.rentals?.days.map((item: any) => {
      return {
        ...item,
        rental_start_time: moment(item.rental_start_time, ["HH:mm:ss"]).format(
          "hh:mm A"
        ),
        rental_end_time: moment(item.rental_end_time, ["HH:mm:ss"]).format(
          "hh:mm A"
        ),
      };
    });
  }

  let j = 0;
  for (const property in responseData?.post_production) {
    if (property.includes("edit_")) {
      data.post_production.edit[j] = responseData?.post_production[property];
      j++;
    }
  }
  data.pre_production = {
    make_storyboard: [
      responseData?.pre_production?.make_storyboard,
      "",
      responseData?.pre_production?.hours_needed_for_storyboard,
    ],
    make_shot_list: [
      responseData?.pre_production?.make_shot_list,
      "",
      responseData?.pre_production?.hours_needed_for_shot_list,
    ],
    make_schedule: [
      responseData?.pre_production?.make_schedule,
      "",
      responseData?.pre_production?.hours_needed_for_make_schedule,
    ],
    make_script: [
      responseData?.pre_production?.make_script,
      "",
      responseData?.pre_production?.hours_needed_for_script,
    ],
    testimony_questions: [
      responseData?.pre_production?.testimony_questions,
      "",
      responseData?.pre_production?.hours_needed_testimony_questions,
    ],
    handle_renting_the_location: [
      responseData?.pre_production?.handle_renting_the_location,
      "",
      responseData?.pre_production?.hours_needed_for_location,
    ],
    // acquire_location_permit: [
    //   responseData?.pre_production?.acquire_location_permit,
    //   "",
    //   responseData?.pre_production?.hours_needed_for_location_permit,
    // ],
    do_location_walkthrough: [
      responseData?.pre_production?.do_location_walkthrough,
      "",
      responseData?.pre_production?.hours_needed_for_location_walkthrough,
    ],
    do_location_scouting: [
      responseData?.pre_production?.do_location_scouting,
      "",
      responseData?.pre_production?.hours_needed_do_location_scouting,
    ],
    // handle_purchasing_prop: [
    //   responseData?.pre_production?.handle_purchasing_prop,
    //   "",
    //   responseData?.pre_production?.hours_needed_for_purchasing_prop,
    // ],
    // need_rehearsal: [
    //   responseData?.pre_production?.need_rehearsal,
    //   "",
    //   responseData?.pre_production?.hours_needed_for_rehearsal,
    // ],
    need_table_read: [
      responseData?.pre_production?.need_table_read,
      "",
      responseData?.pre_production?.hours_needed_for_table_read,
      responseData?.pre_production?.need_table_read_description,
    ],
    need_talent_casting: [
      responseData?.pre_production?.need_talent_casting,
      "",
      responseData?.pre_production?.hours_needed_need_talent_casting,
    ],
    need_prop_access: [
      responseData?.pre_production?.need_prop_access,
      "",
      responseData?.pre_production?.hours_needed_need_prop_access,
    ],
  };

  return data;
};

export const mapInvoiceRequest = (requestData: any) => {
  const request: any = JSON.parse(JSON.stringify(requestData));

  const data: any = {
    private_note: request.private_notes,
    production: {
      no_of_filming_days: request?.client_details?.filming_days_num,
    },
    post_production: {
      no_of_edits: parseInt(request.post_production.no_of_edits),
    },
    pre_production: {},
    client_details: request.client_details,
    invoice_type: request.invoice_type,
    is_quote: request.is_quote ?? 0,
  };

  request?.production_details?.day?.map((x: any, index: number) => {
    data.production[`day_${index + 1}`] = x;
    x?.crew_package?.map((c: any, idx: number) => {
      if (c.size !== "solo") {
        const count = Object.keys(c).filter((key) =>
          key.includes("crew_member")
        ).length;
        c[`crew_member_${count + 1}`] = "Project Manager";
      }
      c.arrival_time = moment(c.arrival_time, ["h:mm A"]).format("HH:mm");
      c.departure_time = moment(c.departure_time, ["h:mm A"]).format("HH:mm");
      data.production[`day_${index + 1}`][`crew_package_${idx + 1}`] = c;
    });
    delete data.production[`day_${index + 1}`].crew_package;
  });
  request?.post_production?.edit?.map((x: any, index: number) => {
    data.post_production[`edit_${index + 1}`] = x;
  });

  const filteredTalentsDays = request.talents.days.map((item: any) => {
    let packages = item["packages"].map((tdi: any) => {
      let arrivalTime = moment(tdi["talent_arrival_time"], ["h:mm A"]).format(
        "HH:mm"
      );
      let departureTime = moment(tdi["talent_departure_time"], ["h:mm A"]).format("HH:mm");

      return {
        location: tdi["location"],
        talent_arrival_time: arrivalTime,
        talent_departure_time: departureTime,
      }

    });

    return {
      date_of_shoot: item["date_of_shoot"],
      // talent_days_needed: item["talent_days_needed"],
      num_of_talents_needed: item["num_of_talents_needed"],
      coordinator: item["coordinator"],
      packages
    };
  });

  data.talents = { ...request.talents, days: filteredTalentsDays };

  const filteredDays = request.rentals.days.map((item: any) => {
    let startTime = moment(item["rental_start_time"], ["h:mm A"]).format(
      "HH:mm"
    );
    let endTime = moment(item["rental_end_time"], ["h:mm A"]).format("HH:mm");
    return {
      date_of_shoot: item["date_of_shoot"],
      location: item["location"],
      rental_start_time: startTime,
      rental_end_time: endTime,
    };
  });

  data.rentals = { ...request.rentals, days: filteredDays };
  const filteredItems = request.custom.number_of_items.map((item: any) => {
    return {
      item_name: item["item_name"],
      item_description: item["item_description"],
      price: item["price"],
    };
  });

  data.custom = { ...request.custom, number_of_items: filteredItems };

  // delete request.client_details.filming_days_num;
  data.pre_production = {
    make_storyboard: request?.pre_production?.make_storyboard[0],
    hours_needed_for_storyboard: request?.pre_production?.make_storyboard[2],
    make_shot_list: request?.pre_production?.make_shot_list[0],
    hours_needed_for_shot_list: request?.pre_production?.make_shot_list[2],
    make_script: request?.pre_production?.make_script[0],
    hours_needed_for_script: request?.pre_production?.make_script[2],
    make_schedule: request?.pre_production?.make_schedule[0],
    hours_needed_for_make_schedule: request?.pre_production?.make_schedule[2],
    testimony_questions: request?.pre_production?.testimony_questions[0],
    hours_needed_testimony_questions:
      request?.pre_production?.testimony_questions[2],
    handle_renting_the_location:
      request?.pre_production?.handle_renting_the_location[0],
    hours_needed_for_location:
      request?.pre_production?.handle_renting_the_location[2],
    // acquire_location_permit:
    //   request?.pre_production?.acquire_location_permit[0],
    // hours_needed_for_location_permit:
    //   request?.pre_production?.acquire_location_permit[2],
    do_location_walkthrough:
      request?.pre_production?.do_location_walkthrough[0],
    hours_needed_for_location_walkthrough:
      request?.pre_production?.do_location_walkthrough[2],
    do_location_scouting: request?.pre_production?.do_location_scouting[0],
    hours_needed_do_location_scouting:
      request?.pre_production?.do_location_scouting[2],
    // handle_purchasing_prop: request?.pre_production?.handle_purchasing_prop[0],
    // hours_needed_for_purchasing_prop:
    //   request?.pre_production?.handle_purchasing_prop[2],
    // need_rehearsal: request?.pre_production?.need_rehearsal[0],
    // hours_needed_for_rehearsal: request?.pre_production?.need_rehearsal[2],
    need_table_read: request?.pre_production?.need_table_read[0],
    hours_needed_for_table_read: request?.pre_production?.need_table_read[2],
    need_table_read_description: request?.pre_production?.need_table_read[3],
    need_talent_casting: request?.pre_production?.need_talent_casting[0],
    hours_needed_need_talent_casting:
      request?.pre_production?.need_talent_casting[2],
    need_prop_access: request?.pre_production?.need_prop_access[0],
    hours_needed_need_prop_access: request?.pre_production?.need_prop_access[2],
  };
  return data;
};
